import React, { Suspense, lazy } from 'react';
import { Routes as SwitchRoutes, Route } from 'react-router-dom';
import NotFoundPage from '../../pages/NotFoundPage';
import { CONDITIONS_MAIN, PRIVACY_POLICY } from '../../constants/constants';

const HomePage = lazy(() => import('../../pages/HomePage'));
const CategoryPage = lazy(() => import('../../pages/CategoryPage'));
const TextPage = lazy(() => import('../../pages/TextPage'));

function Routes() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <SwitchRoutes>
        <Route key="category" path="/category/:category" element={<CategoryPage />} />
        <Route key="conditions" path="/user/conditions" element={<TextPage path={CONDITIONS_MAIN} />} />
        <Route key="conditions" path="/privacypolicy" element={<TextPage path={PRIVACY_POLICY} />} />
        <Route key="home" path="/" element={<HomePage />} />
        <Route key="404" path="*" element={<NotFoundPage />} />
      </SwitchRoutes>
    </Suspense>
  );
}

export default Routes;
