import React, { useEffect, useState } from 'react';
import { PrivacyPolicy } from '@b3w/pp-portal';
import axios from 'axios';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import '../../common/styles/main.sass';
import { Statistic } from '@b3w/react-statistic';
import Routes from '../Routes/Routes';
import Footer from '../Footer/Footer';
import ModalVideo from '../ModalVideo/ModalVideo';
import ScrollTop from '../ScrollTop/ScrollTop';
import '@b3w/pp-portal/dist/portal-bundle.css';
import {
  CONDITIONS_FOOTER,
  ADDRESS_FOOTER,
  BASE_PATH,
  PRIVACY_FOOTER,
} from '../../constants/constants';
import AddToHomeScreen from '../AddToHomeScreen/AddToHomeScreen';
import useFirebase from '../../hooks/useFirebase';

function App() {
  const [text, setText] = useState('');
  const [address, setAddress] = useState('');
  const [dangerousText, setDangerousText] = useState('');

  useFirebase();

  useEffect(() => {
    axios.get(`${BASE_PATH}${CONDITIONS_FOOTER}`)
      .then((response) => {
        if (response.status === 200) {
          setText(response.data);
        }
      })
      .catch(() => {});
    axios.get(`${BASE_PATH}${ADDRESS_FOOTER}`)
      .then((response) => {
        if (response.status === 200) {
          setAddress(response.data);
        }
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    axios.get(`${BASE_PATH}${PRIVACY_FOOTER}`)
      .then((res) => {
        setDangerousText(res.data);
      })
      .catch(() => {});
  }, []);

  return (
    <>
      <Statistic path={`${BASE_PATH}/referer/statistic`} from timeoutMinutes={1} />
      <div className="Main">
        <Routes />
      </div>
      { dangerousText ? <PrivacyPolicy dangerousText={dangerousText} adoption /> : null }
      <Footer text={text} entity={address} />
      <AddToHomeScreen />
      <ModalVideo />
      <ScrollTop />
    </>
  );
}

export default App;
